<template>
      <button id="open-filter-pane" class="flex flex-row ml-3 mt-2 mobileonly:transition mobileonly:duration-300 mobileonly:ease-in-out md:ml-8 md:mt-5" type="button" data-bs-toggle="collapse" data-bs-target="#filterPane" aria-expanded="true" aria-controls="filterPane">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
				stroke="currentColor" class="w-6 h-6 fill-firstBlue">
				<path stroke-linecap="round" stroke-linejoin="round"
					d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
			</svg>

			<h4 class="font-semibold text-firstBlue hover:text-secondBlue ">Filters</h4>
		</button>
  <div class="grid grid-cols-12">

    <div id="filterPane" class="col-span-12 pl-5 pr-5 mobileonly:collapse md:col-span-3 md:mx-5">
      <SearchFilter/>
    </div>
    <div class="col-span-12 mx-2 md:col-span-9 md:mx-10 md:col-start-4">
      <SearchResults/>
    </div>
  </div>
</template>

<script>
import SearchResults from '@/components/SearchResults.vue'
import SearchFilter from '@/components/SearchFilter.vue';
export default {
  name: "SearchView",
  props: {},
  components: { SearchResults, SearchFilter },
  created(){

    
  }
};
</script>
