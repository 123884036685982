<template>
	<!-- dont change categoryName because it is hardcoded with if else statement in child component -->
	<div class="grid grid-cols-3 my-3">
		<button
			class="font-oswald bg-firstBlue rounded text-sm text-white px-1 py-1 col-start-3"
			@click="clearBtn"
		>
			Clear
		</button>
	</div>
	<div>
		<HomeFilterCategory categoryName="Title" filterId="collapseFilterTitle" />
		<HomeFilterCategory categoryName="Consultant" filterId="collapseFilterConsultant" />
		<HomeFilterCategory categoryName="Project" filterId="collapseFilterProject" />
		<HomeFilterCategory categoryName="Asset Class" filterId="collapseFilterAssetClass" />
		<HomeFilterCategory categoryName="Sector" filterId="collapseFilterSector" />
		<HomeFilterCategory categoryName="Geography" filterId="collapseFilterGeography" />
		<HomeFilterCategory categoryName="Year" filterId="collapseFilterYear" />
		<HomeFilterCategory categoryName="Access" filterId="collapseFilterAccess" />
		
	</div>
</template>

<script>
//onClick need to add filterdata
import HomeFilterCategory from "./HomeFilterCategory.vue";
export default {
	name: "HomeFilter",
	props: {},
	data() {
		return {
		};
	},
	components: { HomeFilterCategory },
	methods: {
		clearBtn() {
			try {
				this.$store.commit("clearHomeFilter");
				this.$store.commit("clearHomeAllBox");
			} catch (err) {
				console.warr(err);
			}
		},
	},
	created() {
		this.$store.commit("clearHomeFilter");
		this.$store.commit("clearHomeAllBox");
	},
};
</script>
