<template>
<div class="sticky top-[100vh]">


  <footer id="footer" class="font-roboto bg-white py-1 text-center flex flex-row justify-between px-3 items-center ">
    <div class="text-xs">
      v1.0
    </div> 
    <div class="text-xs">
      <img src="../assets/Advancing_Malaysia.png" alt="a-logo" class="h-9">
    </div>

  </footer>
</div>
</template>

<script>
export default {
  name: "TheFooter",
  props: {},
  data() {
    return {};
  },
};
</script>