
import * as msal from "@azure/msal-browser"


//scopes and APIURL created by Nazmi so that the front end has access to the back end
export const SCOPES = "user.read openid profile email offline_access api://093f6773-281f-4884-9937-53153d675055/Files.Read"
export const APIURL = "https://aps-lcd-codex-searchengine-simple-prod-sea.azurewebsites.net" //CHNAGE FOR PROD

const msalConfig = {
  auth: {
    clientId: "2c3a8863-96ec-4e9c-99f5-49e5c558f7c6", // This is the ONLY mandatory field that you need to supply. this SPA client id
    authority: "https://login.microsoftonline.com/khazanahcommy.onmicrosoft.com", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: "https://lighthouse.khazanah.com.my",////"http://localhost:3000", // CHANGE HERE FOR PROD
    //postLogoutRedirectUri: "", // Simply remove this line if you would like navigate to index page after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            // console.warn(message);
            return;
        }
      }
    }
  }
};



// exporting config object for jest
if (typeof exports !== 'undefined') {
  module.exports = {
    msalConfig: msalConfig,
  };
};




export const myMSALObj = new msal.PublicClientApplication(msalConfig);

