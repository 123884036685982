import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PdfView from "../views/PdfView.vue";
import AboutView from "../views/AboutView.vue";
import ApproachView from "../views/ApproachView.vue";
import FeedbackView from "../views/FeedbackView.vue";
import SearchView from "../views/SearchView.vue";
import GuideView from "../views/GuideView.vue"
import store from '../store'

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
   { path: "/code=:pathMatch(.*)*", name: "others", redirect: "/" },
  {
    path: "/pdf-view/id/:pdfId/page/:pageParam",
    name: "PdfView",
    component: PdfView,
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
  {
    path: "/approach",
    name: "ApproachView",
    component: ApproachView,
  },
  {

    path: "/feedback",
    name: "FeedbackView",
    component: FeedbackView,
  },
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
  },
  {
    path: "/guide",
    name: "GuideView",
    component: GuideView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return { top: 0 }
  }
});

router.beforeEach((to,from)=>{
  // console.log(from)
  store.commit("setPrevLink",from.fullPath)
})

export default router;
