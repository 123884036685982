import { createStore } from "vuex";
import { myMSALObj, SCOPES, APIURL } from "../authConfig";
import "tw-elements";

//should set scope and apiurl in config and import it

export default createStore({
  state: {
    username: "",
    token: "",
    reportList: {},
    authenticated: false,
    searchResult: {},
    searchQuery: "",
    allHomeFilter: [],//use only in home page?
    allSearchFilter: [],// use only for search page 
    titleHomeFilter: [],// this is categorical like A-F ,G-L
    consultantHomeFilter: [],
    projectHomeFilter: [],
    assetClassHomeFilter:[],
    geoHomeFilter:[],
    sectorHomeFilter:[],
    yearHomeFilter: [],
    accessHomeFilter:[],
    titleSearchFilter: [],
    consultantSearchFilter: [],
    projectSearchFilter: [],
    yearSearchFilter: [],
    assetClassSearchFilter:[],
    geoSearchFilter:[],
    sectorSearchFilter:[],
    homeLoading:true,
    searchLoading:true,
    exactMatchCheckBox:false,
    sortCheckBox:"",
    modalSearch:false,
    gaveFeedback:false,
    textFoundClick:"",
    similarityScoreClick:0,
    prevLink:"",
  },
  getters: {
    getSearchQuery(state) {
      return state.searchQuery;
    }
  },
  mutations: {
    setPrevLink(state,value){
      state.prevLink = value;
    },
    setSimilarityScoreClick(state,value){
      state.similarityScoreClick = value;
    },
    setTextFoundClick(state,value){
      state.textFoundClick = value;
    },
    setGaveFeedback(state,value){
      state.gaveFeedback=value;
    },
    setModalSearch(state,value){
      state.modalSearch=value;
    },
    setExactMatchCheckBox(state,value){
      state.exactMatchCheckBox = value;
    },
    setSortCheckBox(state,value){
      state.sortCheckBox = value;
    },
    setSearchLoading(state,value){
      state.searchLoading = value;
    },
    setHomeLoading(state,value){
      state.homeLoading = value;
    },
    setUsername(state, value) {
      state.username = value;
    },
    setToken(state, value) {
      state.token = value;
    },
    setReportList(state, value) {
      value = value.sort((a,b)=>{return b.id-a.id}) 
      state.reportList = value;
    },
    setAuthenticated(state, value) {
      state.authenticated = value;
    },
    setRetryCounter(state, value) {
      state.retryCounter = value;
    },
    setSearchResult(state, value) {
      state.searchResult = value;
    },
    setSearchQuery(state, value) {
      state.searchQuery = value;
    },
    setAllHomeFilter(state, value) {
      state.allHomeFilter = value;
    },

    setFormattedDistinct(state, value) {
      // need to get from meta data not from the api call
      // need to cater for allSearchFilter and allHomeFilter
      let listObjectsSearch = [];
      let listObjectsHome = [];
      let titleListSearch = [];//actual list will NOT include restricted
      let titleListHome = ["A-F", "G-L", "M-R", "S-Z"];//categorical
      let titleListHomeActual =[];//actual list will include restricted
      let consultantListSearch = [];
      let consultantListHome = [];
      let projectListSearch = [];
      let projectListHome = [];
      let yearListSearch = [];
      let yearListHome = [];
      let assetClassListHome =[];
      let assetClassListSearch=[];
      let geoListHome=[];
      let geoListSearch=[];
      let sectorListHome=[];
      let sectorListSearch=[];
      let accessListHome = ["Open","Limited"];//categorical
      
      //open Report will be used for Search filter
      let openReport = value

      
      openReport = openReport.filter(el=>{
        return (el["url"].length>0)
      })
      
      // here the el.something need to be match with in db 
      openReport.forEach(el=>{
        titleListSearch.push(el.title)
        consultantListSearch.push(el.consultant)
        projectListSearch.push(el.project)
        yearListSearch.push(el.year)
        assetClassListSearch.push(el.asset_class)
        geoListSearch.push(el.geography)
        sectorListSearch.push(el.sector)
      })

      value.forEach(el=>{
        titleListHomeActual.push(el.title)
        consultantListHome.push(el.consultant)
        projectListHome.push(el.project)
        yearListHome.push(el.year)
        assetClassListHome.push(el.asset_class)
        geoListHome.push(el.geography)
        sectorListHome.push(el.sector)
      })

      //set is to remove dupe
      titleListSearch = [...new Set(titleListSearch)].sort()
      consultantListSearch = [...new Set(consultantListSearch)].sort()
      projectListSearch = [...new Set(projectListSearch)].sort()
      yearListSearch = [...new Set(yearListSearch)].sort()
      assetClassListSearch = [...new Set(assetClassListSearch)].sort()
      geoListSearch =[...new Set(geoListSearch)].sort()
      sectorListSearch =[...new Set(sectorListSearch)].sort()

      titleListHomeActual = [...new Set(titleListHomeActual)].sort()
      consultantListHome = [...new Set(consultantListHome)].sort()
      projectListHome = [...new Set(projectListHome)].sort()
      yearListHome = [...new Set(yearListHome)].sort()
      assetClassListHome = [...new Set(assetClassListHome)].sort()
      geoListHome =  [...new Set(geoListHome)].sort()
      sectorListHome =  [...new Set(sectorListHome)].sort()

      titleListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "title"+String(ind), type: "title", value: String(el), checked:false });
      });

      titleListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "title"+String(ind), type: "title", value: String(el), checked:false });
      });

      titleListHomeActual.forEach((el,ind)=>{
        listObjectsHome.push({ id: "titleActual"+String(ind), type: "titleActual", value: String(el), checked:false });
      });

      consultantListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "consultant"+String(ind), type: "consultant", value: String(el), checked:false });
      });

      consultantListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "consultant"+String(ind), type: "consultant", value: String(el), checked:false });
      });

      projectListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "project"+String(ind), type: "project", value: String(el), checked:false });
      });

      projectListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "project"+String(ind), type: "project", value: String(el), checked:false });
      });

      yearListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "year"+String(ind), type: "year", value: String(el), checked:false });
      });

      yearListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "year"+String(ind), type: "year", value: String(el), checked:false });
      });

      accessListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "access"+String(ind), type: "access", value: String(el), checked:false });
      });
      
      assetClassListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "assetClass"+String(ind), type: "assetClass", value: String(el), checked:false });
      });
      assetClassListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "assetClass"+String(ind), type: "assetClass", value: String(el), checked:false });
      });

      geoListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "geography"+String(ind), type: "geography", value: String(el), checked:false });
      });

      geoListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "geography"+String(ind), type: "geography", value: String(el), checked:false });
      });

      sectorListSearch.forEach((el,ind)=>{
        listObjectsSearch.push({ id: "sector"+String(ind), type: "sector", value: String(el), checked:false });
      });

      sectorListHome.forEach((el,ind)=>{
        listObjectsHome.push({ id: "sector"+String(ind), type: "sector", value: String(el), checked:false });
      });

      state.allHomeFilter = listObjectsHome
      state.allSearchFilter = listObjectsSearch
    },
    // Home Filter
    addTitleHomeFilter(state, value) {
      state.titleHomeFilter.push(value);
    },
    addConsultantHomeFilter(state, value) {
      state.consultantHomeFilter.push(value);
    },
    addProjectHomeFilter(state, value) {
      state.projectHomeFilter.push(value);
    },
    addYearHomeFilter(state, value) {
      state.yearHomeFilter.push(value);
    },
    addAccessHomeFilter(state,value){
      state.accessHomeFilter.push(value)

    },
    addAssetClassHomeFilter(state,value){
      state.assetClassHomeFilter.push(value)
    },
    addGeoHomeFilter(state,value){
      state.geoHomeFilter.push(value)
    },
    addSectorHomeFilter(state,value){
      state.sectorHomeFilter.push(value)
    },////////continue here
    delTitleHomeFilter(state, value) {
      state.titleHomeFilter = state.titleHomeFilter.filter(
        (item) => item !== value
      );
    },
    delConsultantHomeFilter(state, value) {
      state.consultantHomeFilter = state.consultantHomeFilter.filter(
        (item) => item !== value
      );
    },
    delProjectHomeFilter(state, value) {
      state.projectHomeFilter = state.projectHomeFilter.filter(
        (item) => item !== value
      );
    },
    delYearHomeFilter(state, value) {
      state.yearHomeFilter = state.yearHomeFilter.filter(
        (item) => item != value
      );
    },
    delAssetClassHomeFilter(state, value) {
      state.assetClassHomeFilter = state.assetClassHomeFilter.filter(
        (item) => item != value
      );
    },
    delGeoHomeFilter(state, value) {
      state.geoHomeFilter = state.geoHomeFilter.filter(
        (item) => item != value
      );
    },
    delSectorHomeFilter(state, value) {
      state.sectorHomeFilter = state.sectorHomeFilter.filter(
        (item) => item != value
      );
    },
    delAccessHomeFilter(state,value){
      state.accessHomeFilter=state.accessHomeFilter.filter(item=>item!==value)

    },
    clearHomeFilter(state) {
      state.titleHomeFilter = [];
      state.consultantHomeFilter = [];
      state.projectHomeFilter = [];
      state.yearHomeFilter = [];
      state.accessHomeFilter=[];
      state.assetClassHomeFilter=[];
      state.geoHomeFilter=[];
      state.sectorHomeFilter=[];
    },

    ///search FILTER
    addTitleSearchFilter(state, value) {
      state.titleSearchFilter.push(value);
      state.titleSearchFilter = [...new Set(state.titleSearchFilter)]; //remove dupe
    },
    addConsultantSearchFilter(state, value) {
      state.consultantSearchFilter.push(value);
      state.consultantSearchFilter = [...new Set(state.consultantSearchFilter)]; //remove dupe
    },
    addProjectSearchFilter(state, value) {
      state.projectSearchFilter.push(value);
      state.projectSearchFilter = [...new Set(state.projectSearchFilter)];
    },
    addYearSearchFilter(state, value) {
      state.yearSearchFilter.push(value);
      state.yearSearchFilter = [...new Set(state.yearSearchFilter)];
    },
    addAssetClassSearchFilter(state, value) {
      state.assetClassSearchFilter.push(value);
      state.assetClassSearchFilter = [...new Set(state.assetClassSearchFilter)];
    },
    addGeoSearchFilter(state, value) {
      state.geoSearchFilter.push(value);
      state.geoSearchFilter = [...new Set(state.geoSearchFilter)];
    },
    addSectorSearchFilter(state, value) {
      state.sectorSearchFilter.push(value);
      state.sectorSearchFilter = [...new Set(state.sectorSearchFilter)];
    },
    delTitleSearchFilter(state, value) {
      state.titleSearchFilter = state.titleSearchFilter.filter(
        (item) => item !== value
      );
    },
    delConsultantSearchFilter(state, value) {
      state.consultantSearchFilter = state.consultantSearchFilter.filter(
        (item) => item !== value
      );
    },
    delProjectSearchFilter(state, value) {
      state.projectSearchFilter = state.projectSearchFilter.filter(
        (item) => item !== value
      );
    },
    delYearSearchFilter(state, value) {
      state.yearSearchFilter = state.yearSearchFilter.filter(
        (item) => item != value
      );
    },
    delAssetClassSearchFilter(state, value) {
      state.assetClassSearchFilter = state.assetClassSearchFilter.filter(
        (item) => item != value
      );
    },
    delGeoSearchFilter(state, value) {
      state.geoSearchFilter = state.geoSearchFilter.filter(
        (item) => item != value
      );
    },
    delSectorSearchFilter(state, value) {
      state.sectorSearchFilter = state.sectorSearchFilter.filter(
        (item) => item != value
      );
    },
    clearSearchFilter(state) {
      state.titleSearchFilter = [];
      state.consultantSearchFilter = [];
      state.projectSearchFilter = [];
      state.yearSearchFilter = [];
      state.assetClassSearchFilter=[];
      state.geoSearchFilter=[];
      state.sectorSearchFilter=[];
    },
    setCheckedBoxTrue(state,id){
      state.allSearchFilter[id].checked=true
    },
    clearSearchAllBox(state){
      state.allSearchFilter.forEach((item,id)=>{
        // console.log("inside commit clearAllCheckBox",id)
        state.allSearchFilter[id].checked =false
        //item.checked =false
      })
    },
    clearHomeAllBox(state){
      state.allHomeFilter.forEach((item,id)=>{
        // console.log("inside commit clearAllCheckBox",id)
        state.allHomeFilter[id].checked =false
        //item.checked =false
      })
    }
  },
  actions: {

    async getToken({ commit }, username) {
      const res = await myMSALObj.acquireTokenSilent({
        account: myMSALObj.getAccountByUsername(username),
        scopes: [SCOPES],
      });
      const data = res.idToken; //pass idtoken
      commit("setToken", data);
    },

    async getAllData({ commit, dispatch, state }, [token, username]) {
      // console.log("🚀 ~ file: index.js ~ line 279 ~ getAllData ~ username", username)
      commit("setHomeLoading",true)
      const prevLink = state.prevLink;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      
      myHeaders.append("username", username); // send data to backend
      myHeaders.append("prevLink", prevLink); // send data to backend
      var requestOptions = { headers: myHeaders };

      //below can be cleaned
      try {
        const res = await fetch(`${APIURL}/get-all`, requestOptions);
        // console.log(res.status);
        if (res.status == 401) {
          throw new Error(response.status);
        }
        const data = await res.json();
        // console.log(data);
        commit("setReportList", data);
        commit("setFormattedDistinct",data)
        commit("setHomeLoading",false)
      } catch (error) {
        console.warn(error);
        localStorage.clear();
        commit("setReportList", {});
        //login again and getData again only once
        // console.log(state.retryCounter);

        console.log("Relogin");
        await dispatch("getAuth");
        await dispatch("getAllDataRetry", state.token, state.username);
      }
    },

    async getAllDataRetry({ commit, dispatch, state }, token, username) {
      // //check if data exist already
      // console.log(state.reportList.length)
      // if (state.reportList.length>0) {
      //   console.log('reuse data from cache')
      //   return} //exit if data already exist
      commit("setHomeLoading",true)
      const prevLink = state.prevLink;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`); // Must pass token for every call
      myHeaders.append("username", username); // send data to backend
      myHeaders.append("prevLink", prevLink); // send data to backend
      var requestOptions = { headers: myHeaders };

      //below can be cleaned
      try {
        const res = await fetch(`${APIURL}/get-all`, requestOptions);
        if (res.status == 401) {
          throw new Error(response.status);
        }
        const data = await res.json();
        // console.log(data);
        commit("setReportList", data);
        console.log("Retry", state.username);
        commit("setFormattedDistinct",data)
        commit("setHomeLoading",false)
      } catch (error) {
        console.warn(error);
      }
    },
    async getSearchResult({ commit, dispatch, state }, searchQuery) {
      commit("setSearchLoading",true) //start skeleton loading
      const prevLink = state.prevLink;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      myHeaders.append("prevLink", prevLink); // send data to backend
      var requestOptions = { headers: myHeaders };

      try {
        const res = await fetch(
          `${APIURL}/get-search/${searchQuery}`,
          requestOptions
        ); 
        console.log(res.status);
        if (res.status == 401) {
          throw new Error(response.status);
        }
        const data = await res.json();
        //console.log(data);
        commit("setSearchResult", data);//store the data in global var
        commit("setSearchLoading",false)// stop skeleton Loading
      } catch (error) {//if there is any error like expired token, 
        console.warn(error);
        localStorage.clear();
        commit("setSearchResult", {});//delete search result to prevent bug?
        //login again and getData again only once
        // console.log(state.retryCounter);//retry Counter doesnt work because when refresh everything reset.

        console.log("Relogin");
        await dispatch("getAuth");
        await dispatch("getSearchRetry", searchQuery); // need to change this for maintainability
      }
    },
    async getSearchRetry({ commit, dispatch, state }, searchQuery) {

      commit("setSearchLoading",true)
      const prevLink = state.prevLink;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      myHeaders.append("prevLink", prevLink); // send data to backend
      var requestOptions = { headers: myHeaders };

    
      try {
        const res = await fetch(
          `${APIURL}/get-search/${searchQuery}`,
          requestOptions
        );
        if (res.status == 401) {
          throw new Error(response.status);
        }
        const data = await res.json();
        // console.log(data);
        commit("setSearchResult", data);
        commit("setSearchLoading",false)
        console.log("Retry", state.username);
      } catch (error) {
        console.warn(error);
      }
    },

    async getAllFilter({ commit, state }) {
      const prevLink = state.prevLink;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`); // Must pass token for every call
      myHeaders.append("username", state.username); // send data to backend
      myHeaders.append("prevLink", prevLink); // send data to backend
      var requestOptions = { headers: myHeaders };

      try {
        const res = await fetch(`${APIURL}/get-distinct`, requestOptions);
        if (res.status == 401) {
          throw new Error(response.status);
        }
        const data = await res.json();
        //console.log(data);
        data.title = data.title.sort();
        data.consultant = data.consultant.sort();
        data.project = data.project.sort();
        data.year = data.year.sort().reverse();
        commit("setAllHomeFilter", data);
        //move below fn to getAllData
        //commit("setFormattedDistinct",data)
      } catch (error) {
        console.warn(error);
      }
    },

    async getAuth({ commit }) {
      await myMSALObj.handleRedirectPromise();
      var accounts = myMSALObj.getAllAccounts();
      if (accounts.length === 0) {
        // No user signed in
        await myMSALObj.loginRedirect();
      }
      //find username
      await myMSALObj
        .acquireTokenSilent({
          account: myMSALObj.getAccountByUsername(accounts[0].username),
          scopes: [SCOPES],
        })
        .then((response) => {
          commit("setToken", response.idToken);
          commit("setUsername", accounts[0].username);
          commit("setAuthenticated", true);
        });
    },
  },
  modules: {},
});
