<template>
	<div id="body" class="font-roboto mt-5 w-3/4 m-auto text-justify">
		<h1 class="text-kGreen font-bold text-xl">Contact Us</h1>
		<p class="mt-5">We are here to help if you have any questions!</p>
		<p class="mt-5">Do the search results require improvement?</p>
		<p class="mt-5">Do you have any comments about this web app?</p>
		<p class="mt-5">
			Send us your feedback
			<a
				href="https://forms.office.com/Pages/ResponsePage.aspx?id=9987zva3fU6Ld5CFOFmA0ms6DuX2ERFJlUPKgZy0HKdUM0hLVjBLNUU3MjA0TDhOSkpSRVVBVEY1Ri4u"
				target="_blank"
				class="no-underline text-blue-600 hover:underline"
				>here.</a
			>
		</p>
	</div>
</template>

<script>
export default {
	name: "FeedbackView",
	props: {},
	components: {},
};
</script>
