<template>
	<div class="accordion" id="accordionExample">
		<div class="accordion-item bg-white dark:hover:bg-gray-800">
			<h2 class="accordion-header mb-0" id="accordionHeader">
				<button
					class="hover:bg-gray-100 focus:bg-gray-100 ring-1 ring-gray-200  border-b-0 border-gray-200 active:bg-gray-100 accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none"
					type="button"
					data-bs-toggle="collapse"
					:data-bs-target="faqIDTarget"
					aria-expanded="true"
					:aria-controls="faqID"
				>
				<!-- need to change target and controls to some id -->
					{{ item.q }}
				</button>
			</h2>
			<!-- change id to dynamic  -->
			<div
				:id="faqID" 
				class="accordion-collapse collapse"
				aria-labelledby="accordionHeader"
			>
				<div class="accordion-body py-4 px-5">
					<p><span v-html="item.a"></span></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		"faqID":String,
	"item":Object},
	data() {
		return {
			faqIDTarget: `#${this.faqID}`,
		};
	},
};
</script>
