<template>
	<div v-if="this.$store.state.authenticated" class="bg-[#F8F8F8] bg-cover min-h-screen">
		<SearchModal />
		<FeedbackModal />
		<TheHeader :view="viewRouter" />
		<router-view />
		<TheFooter/>
	</div>
</template>
<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import SearchModal from "./components/SearchModal.vue";
import FeedbackModal from "./components/FeedbackModal.vue";


export default {
	name: "App",
	data() {
		return {};
	},
	methods: {},
	async created() {},
	components: { TheHeader, TheFooter, SearchModal, FeedbackModal },
	computed: {
		viewRouter() {
			return this.$route.name;
		},
		viewQueryRoute() {
			if (this.$route.name == "SearchView") {
				return this.$route.query;
			}
		},
	},
	async created() {
		await this.$store.dispatch("getAuth");
		//need to add try catch,if fail below need to clear local storage and login again
		// to better optimize check if data exist or not , can save some request and better tracker metrics
		await this.$store.dispatch("getAllData", [
			this.$store.state.token,
			this.$store.state.username,
		]);
		console.log("migrated to new EA")
	},
	watch: {
		async viewQueryRoute() {
			this.$store.commit("clearSearchFilter"); //after clear, we add back so that we dont need to remove one by one
			//clear all checkbox
			try {
				this.$store.commit("clearSearchAllBox");
			} catch (err) {
				// console.warn(err);
			}

			//watch changed of search url. if url change auto run below

			// console.log("watch search route query", this.$route.query);
			// console.log(this.$route);
			await this.$store.dispatch("getAuth"); //another getting auth
			let endpoint = this.$route.query.query; // if get back to other page, this will be undefined. but we handle this below. but might want to exit earlier?
			// https://api-codex-searchengine-dev.azurewebsites.net/get-search/whyonline?year=2020&year=2021&year=2019&consultant=Ernst%20and%20Young&consultant=Hamilton%20Lane
			let titleFilterString = "";
			let consultantFilterString = "";
			let projectFilterString = "";
			let yearFilterString = "";
			let assetClassFilterString ="";
			let geographyFilterString="";
			let sectorFilterString ="";
			let exactString = "";
			// add to string and add to store as well
			//the set checkedbox to true somehow doesnt work
			try {
				if (this.$route.query.title.length > 0) {
					let titleCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "title"
					);
					this.$route.query.title.forEach((item) => {
						titleFilterString += `title=${item}&`;
						this.$store.commit("addTitleSearchFilter", item);
						//checkboxupdate
						//change checked to true
						titleCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								);
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch {}
			try {
				if (this.$route.query.consultant.length > 0) {
					let consultantCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "consultant"
					);
					this.$route.query.consultant.forEach((item) => {
						consultantFilterString += `consultant=${item}&`;
						this.$store.commit("addConsultantSearchFilter", item);

						consultantCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								);
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch {}
			try {
				if (this.$route.query.project.length > 0) {
					let projectCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "project"
					);
					this.$route.query.project.forEach((item) => {
						projectFilterString += `project=${item}&`;
						this.$store.commit("addProjectSearchFilter", item);

						projectCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								);
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch {}
			try {
				if (this.$route.query.year.length > 0) {
					let yearCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "year"
					);
					// for every year
					this.$route.query.year.forEach((item) => {
						yearFilterString += `year=${item}&`;
						this.$store.commit("addYearSearchFilter", item);

						//check id of the year in checkbox e.g year0 ,year1
						//find year0, year1 in the entire set

						yearCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								); //
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch (err) {}

			try {
				if (this.$route.query.asset_class.length > 0) {
					let assetClassCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "assetClass"
					);
					// for every year
					this.$route.query.asset_class.forEach((item) => {
						assetClassFilterString += `asset_class=${item}&`;
						this.$store.commit("addAssetClassSearchFilter", item);

						assetClassCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								); //
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch (err) {}

			try {
				if (this.$route.query.geography.length > 0) {
					let geographyCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "geography"
					);
					// for every year
					this.$route.query.geography.forEach((item) => {
						geographyFilterString += `geography=${item}&`;
						this.$store.commit("addGeoSearchFilter", item);

						geographyCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								); //
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch (err) {}

			try {
				if (this.$route.query.sector.length > 0) {
					let sectorCheckBox = this.$store.state.allSearchFilter.filter(
						(el) => el.type == "sector"
					);
					// for every year
					this.$route.query.sector.forEach((item) => {
						sectorFilterString += `sector=${item}&`;
						this.$store.commit("addSectorSearchFilter", item);

						sectorCheckBox.forEach((el) => {
							if (el.value == item) {
								let theID = this.$store.state.allSearchFilter.findIndex(
									(obj) => obj.id == el["id"]
								); //
								this.$store.commit("setCheckedBoxTrue", theID);
							}
						});
					});
				}
			} catch (err) {}


			try {
				if (this.$route.query.exact == "true") {
					exactString = "exact=true";
				}
			} catch {}

			let searchQuery = `${endpoint}?${titleFilterString}${consultantFilterString}${projectFilterString}${yearFilterString}${assetClassFilterString}${geographyFilterString}${sectorFilterString}${exactString}`;

			if (endpoint !== undefined) {
				// console.log(
				// 	"🚀 ~ file: App.vue ~ line 159 ~ viewQueryRoute ~ searchQuery",
				// 	searchQuery
				// );
				await this.$store.dispatch("getSearchResult", searchQuery); //either from data or store
			}
		},
	},
};
</script>
<style></style>
