<template>
	<div id="body" class="font-roboto mt-5  w-3/4 m-auto text-justify ">
		<h1 class="text-kGreen font-bold text-xl">About</h1>
		<p class="mt-5">
			This project was developed in house by the Data and Analytics team led by <a href="mailto: sukri.karim@khazanah.com.my" class="no-underline text-blue-600 hover:underline ...">Sukri Karim</a>, with contributions from IMO,
			Cybersecurity, Data Engineering and ICT.
		</p>
		<p class="mt-2">
			Investments teams stored and published past consultant reports in Investment’s 
			<a href="https://khazanahcommy.sharepoint.com.mcas.ms/sites/MyKhazanah/INV/SitePages/List-of-Consultant-Reports.aspx"
				class="no-underline text-blue-600 hover:underline" target="_blank"
				rel="noopener noreferrer">SharePoint</a>
			site for reference. The reports are saved as <abbr title="Protected PDF. It can be only opened with Azure Information Protection Software.">PPDF</abbr> format and search function for specific title and topic has limitations such as inability to point out the specific page and limitation on keyword search only.
		</p>
		<p class="mt-2">
			Through Project Codex initiative, the team led by D&A worked on developing a bespoke search engine that enable for searches capability that is more robust and answers all the pain points and requirements pointed out to the project team.
	 
		</p>
		<p class="mt-2">
			We created a way to index these PPDFs (similarly to how Google works with websites),
			to allow users to search based on the content of these documents, on top of report
			title, name of consultant etc.
		</p>
		<p class="mt-2">
			To understand how it works, please read more <router-link class="no-underline text-blue-600 hover:underline" to="/approach">here.</router-link> 
		</p>
		
		<h1 class="text-kGreen font-bold text-xl mt-6">Our Team</h1>
		<p class="mt-5">The developers of this search engine are:</p>
		<p class="mt-2">
			1)
			<a href="mailto: nazmi.tarmizi@khazanah.com.my" class="no-underline text-blue-600 hover:underline ...">Syed
				Nazmi Syed Tarmizi Jamalulil</a>
		</p>
		<p class="mt-1">
			2)
			<a href="mailto: nazura.ramli@khazanah.com.my" class="no-underline text-blue-600 hover:underline ...">Nazura
				Ramli</a>
		</p>
		<p class="mt-1">
			3)
			<a href="mailto: koh.wyhow@khazanah.com.my" class="no-underline text-blue-600 hover:underline ...">Koh
				Wyhow</a>
		</p>
		<h1 class="text-kGreen font-bold text-xl mt-8">Changelog</h1>

		<div class="mr-auto -ml-3 mt-1">
			<div class="flow-root">
				<ul class="-mb-8">

					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v1.0</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Official Launch</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">September 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> - Improved display of meta data <br>
											- Search Results are grouped by Title of Reports <br>
											- Added additional filter ( i.e Asset Class, Sector and Geography) <br>
											- Enhancement of search bar. Added "Filter intellisense" , Recent Search
											and Example Search to the Search Pop Up Bar <br>
											- Added Ratings Pop Up <br>
											- Fixed scroll issue <br>
											- Deployed Data Automation from admin to the front end with Azure Functions
											<br>
											- Added guide on how to use <br>
											- Moved Navigation Bar to the top
											- Added Khazanah and Advancing Malaysia logos
											- Changed certain colors to Advancing Malaysia colors
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.3</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Soft Launch</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">July 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> - Improved display of meta data <br>
											- Added sort features for search result <br>
											- Added loading display <br>
											- Added 'Access' filter
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.2</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Beta Launch</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">June 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> Front End writen with Vue 3 Framework <br>
											- Major change to the UI <br>
											Features: <br>
											- Search documents and redirect to the relevant page <br>
											- Filter by title, consultant, project and year<br>
											- Read PDFs and download PPDF(protected with Azure Information Protection)
											<br>
											- Request Access to restricted documents<br>
											Replaced Azure Cognitive Search with Sentence Transformer Model <br>
											Created User Metrics Dashboard for Adoption Analysis and for future NLP
											model fine tuning
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.1</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">MVP</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">April 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> The Web App was deployed in Cloud (Azure) <br>
											- Deployed the Front End(HTML, CSS, JS) to Azure Static Web App with
											Authentication<br>
											- Use Azure Cognitive Search as a placeholder for Sentence Transformer Model <br>
											- Deployed the API (Python) to Azure App Service <br>
											- Using Azure SQL and Blob Storage to store data. <br>
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.0.4</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Proof of Concept</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">February 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> - Replaced Streamlit UI with new Front End using HTML, CSS, JS (in local machine)
											with search and view pdf features <br>
											- Created Back End API with Python (in local machine) <br>
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.0.3</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Experiment</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">January 2022</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> -Replaced FastText Model with <a
												class="no-underline text-blue-600 hover:underline" target="_blank"
												rel="noopener noreferrer"
												href="https://huggingface.co/sentence-transformers/msmarco-distilbert-dot-v5">Sentence
												Transformers Model</a> with Streamlit user interface (on GCP App Engine)
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="relative pb-8">
							<span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
								aria-hidden="true"></span>
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.0.2</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Experiment</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">December 2021</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> Deployed user interface (using Streamlit) on GCP App Engine
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>

					<li>
						<div class="relative pb-8">
							<div class="relative flex items-start space-x-3">
								<div>
									<div class="relative px-1">
										<div
											class="h-8 w-8 bg-secondBlue rounded-full ring-8 ring-[#F8F8F8] flex items-center justify-center">
											<svg class="text-white h-5 w-5" xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24" stroke="currentColor">
												<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
													d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
											</svg>
										</div>
									</div>
								</div>
								<div class="min-w-0 flex-1 py-0">
									<div class="text-md text-gray-500">
										<div>
											<span class="font-medium text-gray-900 mr-2">v0.0.1</span>

											<div
												class="my-0.5 relative inline-flex items-center bg-white rounded-full border border-gray-300 px-3 py-0.5 text-sm">
												<div class="absolute flex-shrink-0 flex items-center justify-center">
													<span class="h-1.5 w-1.5 rounded-full bg-firstGreen"
														aria-hidden="true"></span>
												</div>
												<div class="ml-3.5 font-medium text-gray-900">Experiment</div>
											</div>
										</div>
										<span class="whitespace-nowrap text-sm">December 2021</span>
									</div>
									<div class="mt-2 text-gray-700">
										<p> - Developed in Jupyter Notebook using <a class="no-underline text-blue-600 hover:underline" target="_blank"
												rel="noopener noreferrer" href="https://github.com/facebookresearch/fastText/">FastText NLP model</a> 
											 (by Facebook Research) <br>
											- Used data from KRC News and Analyst Reports Abstract to train the model
										</p>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "AboutView",
	props: {},
	components: {},
};
</script>
