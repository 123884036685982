<template>
	<div v-for="index in 10" :key="index">
		<SkeletonLoading v-show="showLoading" />
	</div>
	<div>
		<div class="flex justify-end">
			<div>
				<div class="dropdown relative pt-3">
					<a
						class="dropdown-toggle px-10 mx-5 py-2 bg-firstBlue text-white font-medium text-xs leading-tight uppercase rounded  hover:bg-secondBlue hover:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out flex items-center whitespace-nowrap"
						href="#"
						type="button"
						id="dropdownMenuButton2"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						Sort By
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="caret-down"
							class="w-2 ml-2"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512"
						>
							<path
								fill="currentColor"
								d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
							></path>
						</svg>
					</a>
					<div
						class="dropdown-menu absolute hidden bg-white text-base py-2 list-none rounded-lg shadow-lg mt-1 m-0 px-3 text-left bg-clip-padding border-none"
						aria-labelledby="dropdownMenuButton2"
					>
						<div id="Date Ascending item">
							<label class="inline-flex items-center dropdown-item ml-3">
								<input
									id="checkbox-item-1"
									type="radio"
									value="asc"
									v-model="sortByValue"
									class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-firstBlue dark:border-gray-500"
								/>
								<label
									for="checkbox-item-1"
									class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
									>Date Ascending</label
								>
							</label>
						</div>
						<div id="Date Descending item">
							<label class="inline-flex items-center dropdown-item ml-3">
								<input
									id="checkbox-item-2"
									type="radio"
									value="desc"
									v-model="sortByValue"
									class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-firstBlue dark:border-gray-500"
								/>
								<label
									for="checkbox-item-2"
									class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
									>Date Descending</label
								>
							</label>
						</div>
						<div id="Most Relevant item">
							<label class="inline-flex items-center dropdown-item ml-3">
								<input
									id="checkbox-item-3"
									type="radio"
									value="rel"
									v-model="sortByValue"
									class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-firstBlue dark:border-gray-500"
								/>
								<label
									for="checkbox-item-3"
									class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
									>Most Relevant</label
								>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div :key="result.index" v-for="result in searchList">
		<SearchResultsDisplay :result="result" />
	</div>
</template>

<script>
import SearchResultsDisplay from "./SearchResultsDisplay.vue";
import SkeletonLoading from "./SkeletonLoading.vue";
export default {
	name: "SearchResults",
	props: {},
	data() {
		return {};
	},
	components: {
		SearchResultsDisplay,
		SkeletonLoading,
	},
	computed: {
		sortByValue: {
			get() {
				return this.$store.state.sortCheckBox;
			},
			set(value) {
				this.$store.commit("setSortCheckBox", value);
			},
		},
		searchList() {
			try {
				if (this.$store.state.sortCheckBox == "asc") {
					let searchData = this.$store.state.searchResult.sort((a, b) => {
						return new Date(a.date) - new Date(b.date);
					});
					return searchData;
				} else if (this.$store.state.sortCheckBox == "desc") {
					let searchData = this.$store.state.searchResult.sort((a, b) => {
						return new Date(b.date) - new Date(a.date);
					});
					return searchData;
				} else if (this.$store.state.sortCheckBox == "rel") {
					// console.log("rel case");

					let searchData = this.$store.state.searchResult.sort((a, b) => {
						return b.scores - a.scores;
					});
					return searchData;
				} else {
					// console.log("else case");
					let searchData = this.$store.state.searchResult.sort((a, b) => {
						return b.scores - a.scores;
					});
					return searchData;
				}
			} catch (error) {
				// console.warn(error);
				return this.$store.state.searchResult;
			}
		},
		showLoading() {
			return this.$store.state.searchLoading;
		},
	},
	async beforeCreate() {},
};
</script>
