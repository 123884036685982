<template>

	<teleport to="#teleport-modal">
		<div id="modal-bg"
			class="fixed z-10 inset-0 overflow-hidden bg-black bg-opacity-25 flex justify-center backdrop-blur-sm mx-0 md:mx-auto"
			v-if="this.$store.state.modalSearch">
			<div id="search-bar-modal" class="flex justify-start items-center flex-col mx-0 bg-white rounded-lg my-12 w-96 md:w-1/2 "
				ref="theModal">
				<button class="ml-auto mr-3 mt-3" @click="this.$store.commit('setModalSearch', false)"><svg
						xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
						stroke="currentColor" stroke-width="2">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg></button>
				<form @submit.prevent="onSubmit" class="pt-2 w-11/12">
					<div class="pt-2 relative mx-auto text-firstBlue">
						<input
							class="border-2 border-gray-300 bg-white h-10 px-7 pr-10 w-full rounded-full text-sm font-roboto focus:outline-none"
							ref="toFocus" v-model="searchQuery" type="search" name="search" placeholder="Search"
							autocomplete="off" />
						<button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
							<svg id="Capa_1" class="text-firstBlue h-4 w-4 fill-current"
								xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
								version="1.1" x="0px" y="0px" viewBox="0 0 56.966 56.966"
								style="enable-background: new 0 0 56.966 56.966" xml:space="preserve" width="512px"
								height="512px">
								<path
									d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
							</svg>
						</button>
					</div>
				</form>
				<div class="self-start">
					<span class="italic text-slate-400 mx-10">Press Enter to search </span>
				</div>

				<div v-if="appliedFilters.length" class="w-11/12 ">
					Applied Filters:
					<ul class="text-white flex flex-row flex-wrap py-2 items-center w-full">
						<li v-for="item in appliedFilters"
							class="px-2 mx-1 border rounded-full cursor-pointer bg-gray-500 hover:bg-[#ed1c23ce]"
							@click="delAppliedFilters(item)">
							{{ item.value }} <span class="text-black font-semibold"> x</span>
						</li>
					</ul>
				</div>

				<div v-if="searchFilterArr.length > 0" class="w-11/12 overflow-hidden">
					<ul class=" text-black ">
						Narrow your search:
						<li v-for="item in searchFilterArr"
							class="flex flex-row justify-between py-2 bg-gray-100 rounded-lg cursor-pointer my-1 hover:bg-thirdBlue"
							@click="setAppliedFilters(item)">
							<div class="px-3">{{ item.type }}: {{ item.value }}</div>
							<div class="px-3">+</div>

						</li>
					</ul>
				</div>
				<!-- suggested text  -->
				<div v-if="searchFilterArr.length == 0 && appliedFilters.length == 0" class="w-11/12 overflow-hidden">
					<ul class=" text-black ">
						Need inspiration? Try these searches:
						<li v-for="item in textSuggestion"
							class="flex flex-row justify-between py-2 bg-gray-100 rounded-lg cursor-pointer my-1 hover:bg-thirdBlue"
							@click="searchSuggested(item)">
							<div class="px-3">{{ item }}</div>


						</li>
					</ul>
				</div>

				<!-- recent searches  -->
				<div v-if="searchFilterArr.length == 0 && appliedFilters.length == 0" class="w-11/12 overflow-hidden">
					<ul class=" text-black ">
						Recent searches:
						<li v-for="item in storedSearches"
							class="flex flex-row justify-between py-2 bg-gray-100 rounded-lg cursor-pointer my-1 hover:bg-thirdBlue"
							@click="searchSuggested(item)">
							<div class="px-3">{{ item }}</div>


						</li>
					</ul>
				</div>

			</div>
		</div>
	</teleport>
</template>

<script>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useStore } from 'vuex'

export default {
	setup() {
		const theModal = ref(null)
		const store = useStore()
		onClickOutside(theModal, () => {
			// console.log("click outside")
			store.commit("setModalSearch", false)
		})
		return { theModal }
	},

	name: "SearchModal",
	props: {
		prevQuery: String,
	},
	components: {},
	data() {
		return {
			searchQuery: "",
			searchFilterArr: [],
			appliedFilters: [],
			textSuggestion: ["market leader in waste management", "effect of copenhagen accord", "e payment adoption", "risk of climate change", "malaysia economy’s gross value added "],
			recentSearch: []
			//watcher titleSearchFilter and others then add to appliedFIlters
			//
		};
	},
	computed: {

		viewRoute() {
			return this.$route.name;
		},
		viewQueryRoute() {
			if (this.$route.name == "SearchView") {
				return this.$route.query;
			}
		},
		viewQueryRoute2() {
			// console.log(this.$route.query)
			return this.$route.query;

		},
		storedSearches() {
			return localStorage.storedSearches === undefined ? [] : [...JSON.parse(localStorage.storedSearches)];
		}
	},
	methods: {
		async onSubmit(e) {

			this.$store.commit("setExactMatchCheckBox", false);//what?
			this.$store.commit("setSearchQuery", this.searchQuery);
			if (this.searchQuery.length > 0) {
				//close the modal 
				this.$store.commit("setModalSearch", false)
				//set the search in localStorage
				let storedSearches = this.storedSearches;
				if (!storedSearches.includes(this.searchQuery)) {
					storedSearches.unshift(this.searchQuery);//add item to array but put at the front
					storedSearches.length = Math.min(storedSearches.length, 5);//make the max number of it 5
					localStorage.storedSearches = JSON.stringify(storedSearches);
					
				}
				this.$router.push({
					name: "SearchView",
					query: {
						query: this.searchQuery,
						title: this.$store.state.titleSearchFilter,
						consultant: this.$store.state.consultantSearchFilter,
						project: this.$store.state.projectSearchFilter,
						year: this.$store.state.yearSearchFilter,
						asset_class:this.$store.state.assetClassSearchFilter,
						geography:this.$store.state.geoSearchFilter,
						sector:this.$store.state.sectorSearchFilter,
						//we need exact match
					},
				});

			} else {
				//notify user to add something
				this.$refs.toFocus.placeholder = "Your Search Text Is Empty. Please Type Your Search Text And Press Enter"
			}

		},
		// if what user type match the filter, autocomplete for that filter will appear
		searchFilter() {
			try {
				if (this.searchQuery.length) {
					//check if length >0
					this.searchFilterArr = this.$store.state.allSearchFilter.filter((filterItem) => {
						return filterItem.value
							.toLowerCase()
							.startsWith(this.searchQuery.toLowerCase());
					});
				} else {
					this.searchFilterArr = [];
				}
			} catch { }
		},
		setAppliedFilters(item, clearText = true) {
			//item is object passing the item with type(con,title,year etc)
			if (item["type"] == "title") {
				this.$store.commit("addTitleSearchFilter", item["value"]);
			}
			if (item["type"] == "consultant") {
				this.$store.commit("addConsultantSearchFilter", item["value"]);
			}
			if (item["type"] == "project") {
				this.$store.commit("addProjectSearchFilter", item["value"]);
			}
			if (item["type"] == "year") {
				this.$store.commit("addYearSearchFilter", item["value"]);
			}
			if (item["type"] == "assetClass") {
				this.$store.commit("addAssetClassSearchFilter", item["value"]);
			}
			if (item["type"] == "geography") {
				this.$store.commit("addGeoSearchFilter", item["value"]);
			}
			if (item["type"] == "sector") {
				this.$store.commit("addSectorSearchFilter", item["value"]);
			}
			//clear search if they set appliedfiters
			this.appliedFilters.push(item);
			this.appliedFilters = [...new Set(this.appliedFilters)];
			if (clearText == true) {
				this.searchQuery = "";
			}

		},
		delAppliedFilters(item) {
			//triggered by Click appliedfilter
			if (item["type"] == "title") {
				this.$store.commit("delTitleSearchFilter", item["value"]);
			}
			if (item["type"] == "consultant") {
				this.$store.commit("delConsultantSearchFilter", item["value"]);
			}
			if (item["type"] == "project") {
				this.$store.commit("delProjectSearchFilter", item["value"]);
			}
			if (item["type"] == "year") {
				this.$store.commit("delYearSearchFilter", item["value"]);
			}
			if (item["type"] == "assetClass") {
				this.$store.commit("delAssetClassSearchFilter", item["value"]);
			}
			if (item["type"] == "geography") {
				this.$store.commit("delGeoSearchFilter", item["value"]);
			}
			if (item["type"] == "sector") {
				this.$store.commit("delSectorSearchFilter", item["value"]);
			}
			// keep only applied filter that dont contain item 
			this.appliedFilters = this.appliedFilters.filter((x) => {
				return x.value != item.value;
			});
		},
		searchSuggested(item) {
			this.searchQuery = item
			this.onSubmit()
		}
	},
	watch: {
		searchQuery() {
			this.searchFilter(); // intellisense
			this.$store.commit("setSearchQuery", this.searchQuery);
		},
		viewRoute() {
			if (this.viewRoute == "home") {
				this.searchQuery = "";
			}
		},
		viewQueryRoute2() {
			//this is not triggering
			// this.searchQuery = this.$route.query.query; //if someone direct enter filter not working
			//update the appliedFilter here and call setAppliedFilters
			try {
				// now do for all
				// this run sequentially
				try {
					// if clear all it doesnt trigger this 
					if (this.$route.query.title !== undefined && this.$route.query.title.length > 0) {
						let titleCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "title"
						);
						this.$route.query.title.forEach((item) => {
							titleCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						// handling clear data
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="title"
							
						})
						
					}
				} catch(err) {
					// console.warn("watcher query, title",err)
					 }

				try {
					if (this.$route.query.consultant !== undefined && this.$route.query.consultant.length > 0) {
						let consultantCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "consultant"
						);
						this.$route.query.consultant.forEach((item) => {
							consultantCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="consultant"
							
						})
					}
				} catch(err) {
					// console.warn("watcher query, consultant",err) 
					}

				try {
					if (this.$route.query.project !== undefined && this.$route.query.project.length > 0) {
						let projectCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "project"
						);
						this.$route.query.project.forEach((item) => {
							projectCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="project"
							
						})
					}
				} catch (err){
					// console.warn("watcher query, project",err)  
					}

				try {
					if (this.$route.query.year !== undefined && this.$route.query.year.length > 0) {
						let yearCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "year"
						);
						// for every year
						this.$route.query.year.forEach((item) => {
							yearCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="year"
							
						})
					}
				} catch (err) {
					// console.warn("watcher query, year",err)
					  }
				try {
					if (this.$route.query.asset_class !== undefined && this.$route.query.asset_class.length > 0) {
						let assetClassCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "assetClass"
						);
						// for every year
						this.$route.query.asset_class.forEach((item) => {
							assetClassCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="assetClass"
							
						})
					}
				} catch (err) {
					// console.warn("watcher query, year",err)
					  }
				try {
					if (this.$route.query.geography !== undefined && this.$route.query.geography.length > 0) {
						let geographyCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "geography"
						);
						// for every year
						this.$route.query.geography.forEach((item) => {
							geographyCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="geography"
							
						})
					}
				} catch (err) {
					// console.warn("watcher query, year",err)
					  }
				
				try {
					if (this.$route.query.sector !== undefined && this.$route.query.sector.length > 0) {
						let sectorCheckBox = this.$store.state.allSearchFilter.filter(
							(el) => el.type == "sector"
						);
						// for every year
						this.$route.query.sector.forEach((item) => {
							sectorCheckBox.forEach((el) => {
								if (el.value == item) {
									this.setAppliedFilters(el, false)
								}
							});
						});
					}else{
						this.appliedFilters = this.appliedFilters.filter(el=>{
							return el.type !="sector"
							
						})
					}
				} catch (err) {
					// console.warn("watcher query, year",err)
					  }


				this.appliedFilters = [...new Set(this.appliedFilters)];
				// console.log("applied Filters inside search modal", this.appliedFilters)
			} catch (err) {
				//  console.warn(err)
				  }

		},
		'$store.state.modalSearch': function () {
			if (this.$route.name == "home") {

			}

			if (this.$store.state.modalSearch == true) {
				this.$nextTick(() => {

					if (this.$route.name == "home") {
						this.searchFilterArr = []
						this.appliedFilters = []
						this.searchQuery = ""
						this.$store.commit("setSearchQuery", "") //only at home page tho
					}

					this.$refs.toFocus.focus()
				})


			}

		},

	},
};
</script>
