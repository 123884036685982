<template>
	<div class="accordion-item bg-white">
		<h4 class="accordion-header mb-0" id="accordionHeader">
			<button
				class="accordion-button collapsed relative flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 rounded-t-xl border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:focus:text-firstBlue"
				type="button"
				data-bs-toggle="collapse"
				:data-bs-target="filterIdTarget"
				aria-expanded="true"
				:aria-controls="filterId"
			>
				{{ categoryName }}
			</button>
		</h4>
		<div
			:id="filterId"
			class="accordion-collapse collapse"
			aria-labelledby="accordionHeader"
		>
			<div class="accordion-body py-4 px-5 overflow-y-auto h-48">
				<div v-for="item in data" :key="item">
					<div>
						<input
							type="checkbox"
							:id="setId(item.value)"
							:value="item.value"
							:name="categoryName"
							@input="updateCheckBox($event, item.value, categoryName)"
							v-model="item.checked"
						/>
						<label :for="setId(item.value)">&nbsp;{{ item.value }}</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomeFilterCategory",
	props: {
		categoryName: String,
		// data: Object, //move to computed
		filterId: String,
	},
	data() {
		return {
			filterIdTarget: `#${this.filterId}`,
		};
	},

	methods: {
		setId(data) {
			try {
				//console.log("inside setid method",data)
				return data.replace(/\s/g, "");
			} catch {}
		},
		updateCheckBox(event, item, categoryName) {
			//this.$emit('update:checked',event,target.checked)
			if (categoryName == "Title") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addTitleHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delTitleHomeFilter", item);
				}
			}
			if (categoryName == "Consultant") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addConsultantHomeFilter", item);
					// console.log(this.$store.state.consultantHomeFilter);
				} else {
					//remove from array
					this.$store.commit("delConsultantHomeFilter", item);
					// console.log(this.$store.state.consultantHomeFilter);
				}
			}
			if (categoryName == "Project") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addProjectHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delProjectHomeFilter", item);
				}
			}
			if (categoryName == "Asset Class") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addAssetClassHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delAssetClassHomeFilter", item);
				}
			}
			if (categoryName == "Geography") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addGeoHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delGeoHomeFilter", item);
				}
			}
			if (categoryName == "Year") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addYearHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delYearHomeFilter", item);
				}
			}
			if (categoryName == "Access") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addAccessHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delAccessHomeFilter", item);
				}
			}
			if (categoryName == "Sector") {
				//commit to store
				if (event.target.checked) {
					//add in array
					this.$store.commit("addSectorHomeFilter", item);
				} else {
					//remove from array
					this.$store.commit("delSectorHomeFilter", item);
				}
			}
			// console.log(item, event.target.checked);
		},
		isChecked(item) {
			let allFilter = [
				this.$store.state.titleHomeFilter,
				this.$store.state.consultantHomeFilter,
				this.$store.state.projectHomeFilter,
				this.$store.state.yearHomeFilter,
				this.$store.state.accessHomeFilter,
				this.$store.state.assetClassHomeFilter,
				this.$store.state.geoHomeFilter,
				this.$store.state.sectorHomeFilter,
			].flat();

			let checkedBool = allFilter.includes(item);

			return checkedBool;
		},
	},
	computed: {
		data() {
			if (this.categoryName == "Title") {
				//select list based on key object
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "title");

				return field;
			}
			if (this.categoryName == "Consultant") {
				let field = this.$store.state.allHomeFilter.filter(
					(el) => el.type == "consultant"
				);
				return field;
			}
			if (this.categoryName == "Project") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "project");
				return field;
			}
			if (this.categoryName == "Year") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "year");
				return field.sort().reverse();
			}

			if (this.categoryName == "Access") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "access");
				return field;
			}

			if (this.categoryName == "Asset Class") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "assetClass");
				return field;
			}

			if (this.categoryName == "Geography") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "geography");
				return field;
			}

			if (this.categoryName == "Sector") {
				let field = this.$store.state.allHomeFilter.filter((el) => el.type == "sector");
				return field;
			}
		},
	},
};
</script>
