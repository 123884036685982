<template>
	<div class="flex flex-col">
		<h1 class="text-lg text-gray-700 text-center font-roboto pb-5">
			<h1 class="font-bold text-2xl pt-3 pb-5">{{ getTitle(this.id) }}</h1>
		</h1>
		<div class="fixed inset-0 flex items-center justify-center" v-show="showLoading">
			<div class="w-40 h-40 border-t-4 border-b-4 border-gray-700 rounded-full animate-spin"></div>
		</div>
		<iframe :src="src" frameborder="0"></iframe>

	</div>
</template>

<script>
import { APIURL } from "@/authConfig";


export default {
	name: "ThePdfViewer",
	props: {
		pageParam: String,
		id: String,
	},
	components: {},
	methods: {
		getTitle(id) {
			try {
				let title = this.$store.state.reportList.find((a) => a.id == id).title;
				return title;
			} catch (error) {
				// console.warn(error);
				return null;
			}
		},
	},
	data() {
		return {
			file: "",
			src: "",
			showLoading: true
		};
	},
	async created() {
		// console.log("route inside pdf viewer",this.$route)
		// get PDF 
		this.showLoading = true
		await this.$store.dispatch("getAuth");
		//get pdf file by calling the api in data store
		let prevLink = this.$store.state.prevLink
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`);
		myHeaders.append("username", this.$store.state.username);
		myHeaders.append("prevLink", prevLink); // send data to backend
		var requestOptions = {
			headers: myHeaders,
		};

		const res = await fetch(`${APIURL}/get-pdf-file-by-id/${this.id}`, requestOptions);
		const data = await res.blob();
		this.file = URL.createObjectURL(data);
		// console.log(this.file)
		this.src = `../../../../lib/pdfjs-2.12.313-dist/web/viewer.html?file=${this.file}&#page=${this.pageParam}`;
		//console.log("src",this.src)
		this.showLoading = false



		// tracker 

		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`);
		myHeaders.append("username", this.$store.state.username);
		myHeaders.append("id", this.id); // send data to backend
		myHeaders.append("textFound", encodeURIComponent(this.$store.state.textFoundClick));
		myHeaders.append("searchQuery", this.$store.state.searchQuery);
		myHeaders.append("similarityScore", Number(this.$store.state.similarityScoreClick));
		myHeaders.append("prevLink", prevLink); // important to validate our calc later
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
		};

		const x = await fetch(`${APIURL}/set-pdf-metrics`, requestOptions);
	},
};
</script>
<style scoped>
iframe {
	margin: auto;
	padding-left: 5px;
	width: 98%;
	height: 720px;
}
</style>
