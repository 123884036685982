<template>
	<div class="card rounded-lg pl-5 pb-2 bg-white my-5">
		<div id="title" class="py-3">
			<div id="report-title">
				<div class="flex flex-row justify-between items-start">
					<!-- span below is just the title with icons lock  -->
					<div
						class=" flex flex-row font-roboto font-semibold text-left text-md text-firstGreen w-3/5 items-start justify-items-start md:text-lg"
					>
						<span v-show="restrictedDoc">{{ meta.title }}</span>
						<span v-show="!restrictedDoc"><router-link :to="getLink">{{ meta.title }}</router-link></span>
						
						<h1 class="text-firstGreen pt-1" v-show="restrictedDoc">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-5 w-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</h1>
					</div>
					<div id="date" class="flex flex-col pl-5 md:flex-row">
						<span class="text-slate-600 font-light text-sm">Report Date: </span>
						<span class="text-right text-slate-600 font-medium pr-4 text-sm">{{
							meta.date
						}}</span>
					</div>
				</div>
			</div>

		</div>

		<div id="meta-data" class="grid grid-cols-3 md:grid-cols-12 md:pt-4">
			<div class="md:pr-5 md:py-1 md:col-span-3">
				<p class="text-slate-400 font-light text-xs md:text-sm">Project</p>
				<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ meta.project }}</p>
			</div>
			<div class="md:pr-5 md:py-1 md:col-span-3">
				<p class="text-slate-400 font-light text-xs md:text-sm">Sector</p>
				<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ meta.sector }}</p>
			</div>
			<div class="md:pr-5 md:py-1 md:col-span-3">
				<p class="text-slate-400 font-light text-xs md:text-sm">Portfolio</p>
				<p class="text-slate-600 font-medium text-xs md:text-sm">{{ meta.port }}</p>
			</div>
			<div class="md:pr-5 md:py-1 md:col-span-3">
				<p class="text-slate-400 font-light text-xs md:text-sm">Consultant</p>
				<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ meta.consultant }}</p>
			</div>
			<div class="md:pr-5 md:py-1 md:col-span-3 ">
				<p class="text-slate-400 font-light text-xs md:text-sm">Asset Class</p>
				<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ meta.asset_class }}</p>
			</div>
			<div class="md:pr-5 md:py-1 md:col-span-3">
				<p class="text-slate-400 font-light text-xs md:text-sm ">Geography</p>
				<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ meta.geography }}</p>
			</div>
		</div>
		<div>
			<p class="text-slate-400 font-light pt-2 text-xs md:pt-4 md:text-sm">Description</p>
			<p class="text-slate-600 font-medium text-xs md:pt-4 md:text-sm">{{ meta.desc }}</p>
		</div>
		<div id="document hyperlink">
			<div id="unrestricted" v-show="!restrictedDoc">
				<p class="underline font-light text-firstGreen hover:text-secondGreen pt-1 md:pt-3">
					<a :href="meta.url" target="_blank" rel="noopener noreferrer"
						>Download PPDF Here</a
					>
				</p>
			</div>
			<div id="restricted" v-show="restrictedDoc">
				<p class="underline font-light text-firstGreen hover:text-secondGreen pt-1 md:pt-3">
					<a
						v-bind:href="
							'https://outlook.office.com/owa/?path=/mail/action/compose&to=honiz@khazanah.com.my?cc=daniel.tan@khazanah.com.my&subject=Request+Access+to+Restricted+Consultant+Report&body=Dear+Honiz+%2F+IMO+team%2C%0D%0A%0D%0AI+would+like+to+request+access+to+this+document+titled%3A%0D%0A%0D%0A' +
							meta.title +
							'%0D%0A%0D%0AAppreciate+it+if+you+can+email+me+the+file+accordingly.+Thank+you%21%0D%0A%0D%0ARegards'
						"
						target="_blank"
						rel="noopener noreferrer"
						>Request Access Here</a
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomeDataDisplay",
	props: {
		meta: Object,
	},
	computed: {
		getLink() {
			return `/pdf-view/id/${this.meta.id}/page/0`;
		},
		restrictedDoc() {
			if (this.meta.url.length == 0) {
				return true;
			} else return false;
		},
	},
	components: {},
};
</script>

<style></style>
