<template>
	<div v-for="index in 10" :key="index">
		<SkeletonLoading v-show="showLoading" />
	</div>

	<div :key="meta.id" v-for="meta in filterList">
		<HomeDataDisplay :meta="meta" />
	</div>
</template>

<script>
import HomeDataDisplay from "./HomeDataDisplay.vue";
import SkeletonLoading from "./SkeletonLoading.vue";
export default {
	name: "HomeData",
	props: {},
	computed: {
		showLoading() {
			// trigger on off whether to show skeleton loading
			return this.$store.state.homeLoading;
		},
		filterList() {
			try {
				let title = [];
				let consultant = [];
				let project = [];
				let year = [];
				let access = [];
				let assetClass=[];
				let geography =[];
				let sector =[];
				//check if filter empty, if empty then add all for the category,
				if (this.$store.state.titleHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "titleActual")
						.forEach((x) => title.push(x.value));
					title = title.map((x) => {
						return x[0]; //return first letter //
					});
				} else {
					//map the filter checkbox to letters, to filter first character
					title = this.$store.state.titleHomeFilter.map((x) => {
						if (x == "A-F") {
							return ["A", "B", "C", "D", "E", "F", "a", "b", "c", "d", "e", "f"];
						}
						if (x == "G-L") {
							return ["G", "H", "I", "J", "K", "L", "g", "h", "i", "j", "k", "l"];
						}
						if (x == "M-R") {
							return ["M", "N", "O", "P", "Q", "R", "m", "n", "o", "p", "q", "r"];
						}
						if (x == "S-Z") {
							return [
								"S",
								"T",
								"U",
								"V",
								"W",
								"X",
								"Y",
								"Z",
								"s",
								"t",
								"u",
								"v",
								"w",
								"x",
								"y",
								"z",
							];
						}
					}); //
					//then flatten things. make multiple arrays become just one array
					title = title.flat();
				}
				if (this.$store.state.consultantHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "consultant")
						.forEach((x) => consultant.push(x.value));
				} else {
					this.$store.state.consultantHomeFilter.forEach((x) => consultant.push(x));
				}
				if (this.$store.state.projectHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "project")
						.forEach((x) => project.push(x.value));
				} else {
					this.$store.state.projectHomeFilter.forEach((x) => project.push(x));
				}
				if (this.$store.state.yearHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "year")
						.forEach((x) => year.push(parseInt(x.value)));
				} else {
					this.$store.state.yearHomeFilter.forEach((x) => year.push(parseInt(x)));
				}

				if (this.$store.state.accessHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "access")
						.forEach((x) => access.push(x.value));
				} else {
					this.$store.state.accessHomeFilter.forEach((x) => access.push(x));
				}

				if (this.$store.state.assetClassHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "assetClass")
						.forEach((x) => assetClass.push(x.value));
				} else {
					this.$store.state.assetClassHomeFilter.forEach((x) => assetClass.push(x));
				}

				if (this.$store.state.geoHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "geography")
						.forEach((x) => geography.push(x.value));
				} else {
					this.$store.state.geoHomeFilter.forEach((x) => geography.push(x));
				}

				if (this.$store.state.sectorHomeFilter.length == 0) {
					this.$store.state.allHomeFilter
						.filter((x) => x.type == "sector")
						.forEach((x) => sector.push(x.value));
				} else {
					this.$store.state.sectorHomeFilter.forEach((x) => sector.push(x));
				}


				// x.something need match with db name
				//if all cond is true then keep that report
				var filtered = this.$store.state.reportList.filter((x) => {
					var titleBool = title.includes(x.title[0]);
					var consultantBool = consultant.includes(x.consultant);
					var projectBool = project.includes(x.project);
					var yearBool = year.includes(parseInt(x.year));
					var accessBool = access.includes(x.url.length > 0 ? "Open" : "Limited");
					var assetClassBool = assetClass.includes(x.asset_class);
					var geographyBool = geography.includes(x.geography);
					var sectorBool = sector.includes(x.sector);
					var mainBool =
						titleBool && consultantBool && accessBool && projectBool && yearBool  && geographyBool && assetClassBool && sectorBool;
					return mainBool;
				});

				//issue is so slow(relatively refer vue debug tool under performance) when uncheck
				console.log("filtered", filtered);
				return filtered;
			} catch (err) {
				// console.warn(err);
				return this.$store.state.reportList;
			}
		},
	},
	components: { HomeDataDisplay, SkeletonLoading },
	async beforeCreate() {},
};
</script>
