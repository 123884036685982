<template>
	<div class="relative flex">
		<div id="sidebar" class="w-40 fixed left-0 top-20 h-screen pl-5 hidden md:block">
			<p class="pr-10 pl-3 pb-5 pt-10"><b>TABLE OF CONTENTS</b></p>
			<a href="#getting-started">• Getting Started</a>
			<br /><br />
			<a href="#home-filter">• Home Filter</a>
			<br /><br />
			<a href="#search-feature">• Using the Search Feature</a>
			<br /><br />
			<a href="#filter-and-sort">• Filtering and Sorting the Search Result</a>
			<br /><br />
			<a href="#faq">• FAQ</a>
		</div>
		<div id="body" class="font-roboto mt-5 w-3/4 m-auto pb-10 ">
			<div id="getting-started">
				<h1 class="text-kGreen font-bold text-xl pb-2">Getting Started</h1>

				<p><br />This web application contains two types of consultant reports.</p>
				<div id="open" class="text-left pt-3">
					<p class="text-gray-700">
						<b>• Open Access</b>
					</p>
					<img
						class="justify-left shadow-lg rounded-md"
						src="../assets/open_access.png"
						alt=""
					/>
					<p class="pl-5 text-sm pt-2">
						For documents with open access, you can search through the contents using the
						search feature and download the <br />Protected PDF (PPDF) of the report by
						clicking <u>Download PPDF Here</u>.
					</p>
				</div>
			</div>
			<div id="limited" class="text-left">
				<p class="text-gray-700">
					<b> <br />• Limited Access</b>
				</p>
				<p class="pl-5 text-sm">Notice the lock icon next to the title</p>
				<img
					class="justify-left shadow-lg rounded-md"
					src="../assets/limited_access.png"
					alt=""
				/>
				<p class="pl-5 text-sm pt-2">
					For documents with limited access, due to its confidentiality, you can click on
					<u>Request Access Here</u> to have the IMO team <br />email the report directly
					to your signed in email address.
				</p>
			</div>
			<br /><br />
			<div id="home-filter" class="bg-white border-grey-500">
				<p class="text-kGreen pl-5 py-5"><b>Home Filter</b><br /></p>
				<p class="text-sm pl-5 pt-2">
					Filter the reports on the home page based on the categories listed on the filter
					pane. <br />Click on the dropdown and mark the checkbox to filter.
				</p>
				<img
					class="pl-10 py-5 ml-5 justify-left"
					src="../assets/home_filter.png"
					alt=""
				/>
			</div>
			<br /><br />
			<div id="search-feature" class="bg-white border-grey-500">
				<p class="text-kGreen pl-5 py-5"><b>Using the Search Feature</b></p>
				<p class="pl-5 text-sm"><b>Step 1:</b> Click on the search box</p>
				<img class="pl-10 ml-5 justify-left" src="../assets/step1.png" alt="" />
				<p class="pl-5 pt-3 text-sm">
					<b>Step 2:</b> On the search popup, type in your query and press enter, or click
					on any of the suggested phrases.
				</p>
				<img class="justify-left pl-10 ml-5" src="../assets/step2.png" alt="" />
				<p class="pl-10 ml-5 text-sm">
					<br />NOTE: If the input keyword match any of the words contained in the reports
					meta data, there will be suggestions to apply it as a filter.
				</p>
				<p class="indent-12 pl-10 text-sm">Example below:</p>

				<img class="justify-left pl-10 ml-5" src="../assets/suggest.png" alt="" />
				<p class="pl-5 text-sm">
					<br /><b>Step 3: </b>On the search results page, click on the report's title to
					checkout the returned match for your query.
				</p>
				<p class="pl-5 text-sm pt-3">
					Clicking on the title will open the PDF viewer and direct you to the exact page
					containing the text found.
				</p>

				<img class="justify-left pl-10" src="../assets/step3.png" alt="" />
				<p class="pl-5 pr-10 text-sm">
					<br /><br />In the case where the report contains multiple search results,
					clicking on the title will open the first text found. <br /><br />Alternatively,
					you can click on the ‘Text Found in the Document’ dropdown. This will show you
					the list of texts found . Kindly click on any of them to go to their exact page.
				</p>
				<img class="justify-left pl-10 ml-5" src="../assets/textfound.png" alt="" />
				<p class="pl-5 text-sm">
					<br />PDF view of the report directing straight to the page containing the text
					found <br />
				</p>
				<img class="justify-left pl-10 ml-5" src="../assets/pdfview.png" alt="" />
				<p class="px-10 mx-5 text-sm">
					<br />NOTE: Due to its confidentiality, you will not be able to print or save
					the report from this viewer page. Kindly go back to the result page to download
					the Protected PDF of the report instead.
				</p>
			</div>
			<br /><br />
			<div id="filter-and-sort" class="bg-white border-grey-500">
				<p class="text-kGreen pl-5 py-5">
					<b>Filtering and Sorting the Search Result</b>
				</p>
				<p class="pl-5 text-sm">
					To filter the search results further, select the criteria on the filter pane and
					click Apply Filter.<br />
				</p>
				<p class="indent-12 text-sm pt-5">For example:</p>
				<p class="indent-20 text-sm">1. Select BCG under the Consultant filter. <br /></p>
				<p class="indent-20 text-sm">2. Click Apply Filter.</p>

				<img class="justify-left pl-10 py-5" src="../assets/filter1.png" alt="" />
				<p class="indent-12 text-sm pt-5">
					Result is filtered further to only contain reports from BCG
				</p>
				<img class="justify-left pl-10" src="../assets/filter2.png" alt="" />
				<p class="indent-12 text-sm pt-10">
					Result can also be <b>sorted by date and relevance.</b>
				</p>
				<img class="justify-left pl-10 pt-3 pb-5" src="../assets/filter3.png" alt="" />
			</div>
			<div id="faq">
				<br />
				<p class="pb-3">FAQs</p>
				<FAQItem
					v-for="(f, index) in items"
					:faqID="f.id.toString()"
					:key="index"
					:item="f"
					:index="index"
					@click="toggleItem(index)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import FAQItem from "../components/FAQItem.vue";
import faqItemsData from "../assets/faqitems.json";
export default {
	name: "GuideView",
	props: {},
	components: { FAQItem },
	data() {
		return {
			items: faqItemsData,
		};
	},
	methods: {
		toggleItem(index) {
			this.isActive = index;
			console.log(index);
		},
	},
};
</script>
