<template>
	<!-- Add some rules to have logic if it is home page or other page. if home show differently -->

	<div id="header" :class="[otherView ? 'bg-white pb-2' : '']">
		<div id="other-header" class="grid grid-cols-2 grid-rows-2 pt-1  md:grid-cols-10 md:grid-rows-1 md:pl-1"
			v-show="otherView">
			<div id="left-header"
				class="flex col-start-1 col-span-9  items-center  gap-x-1 flex-row justify-start  md:col-span-6 md:gap-x-2">

				<BaseKLogo logoStyle="h-20 w-21 hidden md:block" />
				<h6 class="font-roboto text-gray-500 font-bold ml-2 text-center hover:text-secondBlue md:text-md">
					<router-link to="/" class="md:text-md">Lighthouse</router-link>
				</h6>

				<div id="search bar" class="ml-1 -mt-1 pr-1 w-2/3">
					<BaseSearchBar />
				</div>
			</div>
			<div id="middle-header"
				class="flex col-start-1 row-start-2 col-span-10 flex-row  items-center justify-center justify-items-center md:col-start-7  md:row-start-1 md:justify-end md:col-span-4 gap-4">
				
					<ul class="font-roboto hover:text-secondBlue flex flex-row justify-between items-center gap-4">
						<li class="">
							<router-link to="/about" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									About
								</p>
							</router-link>
						</li>
						<li class="">
							<router-link to="/approach" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									How it works
								</p>
							</router-link>
						</li>
						<li class="">
							<router-link to="/guide" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									User Guide
								</p>
							</router-link>
						</li>

						<li class="">
							<router-link to="/feedback" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									Feedback
								</p>
							</router-link>
						</li>
						
					</ul>
					
				<div class="hidden md:block">
					<TheHeaderPP id="right-header" class=" my-auto hidden md:block" />
				</div>
				

			</div>

			<TheHeaderPP id="right-header" class="row-start-1 col-start-10 my-auto md:hidden" />

		</div>
		<div id="home-header flex flex-col" v-show="!otherView">
			<div id="header-1"
				class="grid grid-cols-2 pt-1 md:pb-3 md:grid-cols-10 md:grid-rows-1 md:pl-1">
				<div id="left-header-home"
					class="flex col-start-1 col-span-9 items-center  gap-x-1 flex-row justify-start  md:col-span-6 md:gap-x-2">
					<div id="k-logo">
						<BaseKLogo logoStyle="h-20 w-21" />
					</div>
					<div id="a-logo">
						<img src="../assets/Advancing_Malaysia.png" alt="a-logo" class="h-12">
					</div>
				</div>
				<div id="middle-header-home"
				class="flex col-start-1 row-start-2 col-span-10 flex-row  items-center justify-center justify-items-center md:col-start-7  md:row-start-1 md:justify-end md:col-span-4 gap-4">
				
					<ul class="font-roboto hover:text-secondBlue flex flex-row justify-between items-center gap-4">
						<li class="">
							<router-link to="/about" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									About
								</p>
							</router-link>
						</li>
						<li class="">
							<router-link to="/approach" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									How it works
								</p>
							</router-link>
						</li>
						<li class="">
							<router-link to="/guide" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									User Guide
								</p>
							</router-link>
						</li>

						<li class="">
							<router-link to="/feedback" class="no-underline hover:underline">
								<p
									class="overflow-hidden font-roboto text-firstBlue hover:text-secondBlue font-bold text-sm">
									Feedback
								</p>
							</router-link>
						</li>
						
					</ul>
					
				<div class="hidden md:block">
					<TheHeaderPP id="right-header" class=" my-auto hidden md:block" />
				</div>
				

			</div>

			<TheHeaderPP id="right-header" class="row-start-1 col-start-10 my-auto md:hidden" />
			</div>

			<div id="logo-image" class="flex justify-center md:pb-5">
				<h1 class=" text-4xl text-gray-400 col-start-1 font-rosario uppercase text-center pt-3 md:text-8xl">
					Light
				</h1>
				<div id="logo image" class="col-start-2 w-[60px] md:w-[120px]">
					<BaseLogo />
				</div>
				<h1 class="text-4xl text-gray-400 col-start-3 font-rosario uppercase text-center pt-3 md:text-8xl">
					House
				</h1>
			</div>
			<div id="introduction">
				<h3 class="font-bold text-md text-gray-700 text-center font-roboto md:text-2xl">
					The search engine for
					<a href="https://khazanahcommy.sharepoint.com.mcas.ms/sites/MyKhazanah/INV/SitePages/List-of-Consultant-Reports.aspx"
						class="no-underline hover:text-secondGreen hover:underline" target="_blank"
						rel="noopener noreferrer">consultant reports
					</a>
				</h3>
			</div>
			<div id="search-bar" class="mx-auto mt-2 w-3/5 md:w-1/4 ">
				<BaseSearchBar />
			</div>
		</div>
	</div>
</template>

<script>
import BaseSearchBar from "./BaseSearchBar.vue";
import TheHeaderPP from "./TheHeaderPP.vue";
import BaseLogo from "./BaseLogo.vue";
import BaseKLogo from "./BaseKLogo.vue";

export default {
	name: "TheHeader",
	props: {
		view: String,
	},
	data() {
		return {};
	},
	computed: {
		otherView() {
			if (this.view == "home") {
				return false;
			} else return true;
		},
	},
	components: { BaseSearchBar, TheHeaderPP, BaseLogo, BaseKLogo },
};
</script>
