<template>
	<teleport to="#teleport-modal2">
		<!-- we may not need modal-bg -->
		<div
			id="modal-bg"
			class="fixed inset-0 overflow-hidden flex flex-col w-3/4 mx-auto md:w-1/4 md:z-10 md:top-0 md:right-0 md:mr-0"
			v-if="modalFeedback && !this.$store.state.gaveFeedback"
		>
			<div
				id="feedback-modal"
				class="flex flex-col bg-white rounded-[18px] my-5 drop-shadow-lg px-4 border-2 border-slate-100"
			>
				<div>
					<div class="flex flex-row mt-2">
						<h2 class="font-semibold">How is your experience?</h2>
						<!-- close button  -->
						<button class="ml-auto" @click="modalFeedback = false">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="h-5 w-5"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								stroke-width="2"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<p class="text-slate-500">
						Any further comment? Tell us
						<a
							href="https://forms.office.com/Pages/ResponsePage.aspx?id=9987zva3fU6Ld5CFOFmA0ms6DuX2ERFJlUPKgZy0HKdUM0hLVjBLNUU3MjA0TDhOSkpSRVVBVEY1Ri4u"
							target="_blank"
							class="no-underline text-blue-600 hover:underline"
							>here</a
						>.
					</p>

					<p class="text-slate-500">1:Worst; 5:Best</p>
				</div>
				<!-- Rating  and Submit Button-->
				<form @submit.prevent="onSubmit()" name="select-rating" class="grid gap-4 my-3">
					<fieldset class="flex justify-between items-center border-none">
						<!-- need to create formGroups and rating pick -->
						<div
							v-for="formGroup in formGroups"
							:key="formGroup"
							class="grid w-10 h-10 rounded-full place-items-center"
						>
							<input
								type="radio"
								name="rating"
								:id="formGroup"
								:value="formGroup"
								class="absolute rounded-full opacity-0 cursor-pointer z-0 peer"
								v-model="answer"
							/>
							<label
								:for="formGroup"
								class="grid bg-[#bcbec0] rounded-full w-full h-full place-items-center p-auto cursor-pointer text-white z-10 hover:bg-[#419243] peer-checked:bg-[#419243]"
								>{{ formGroup }}</label
							>
						</div>
					</fieldset>
					<button
						type="submit"
						title="submit review"
						class="rounded-2xl bg-[#419243] text-white mx-5"
					>
						Submit
					</button>
				</form>
			</div>
		</div>
	</teleport>
</template>

<script>
import { APIURL } from "@/authConfig";
export default {
	name: "FeedbackModal",
	props: {},
	components: {},
	data() {
		return {
			formGroups: [1, 2, 3, 4, 5],
			answer: 5,
			modalFeedback: false,
		};
	},
	computed: {
		router() {
			return this.$route.name;
		},
	},
	methods: {
		async onSubmit(e) {
			// submit to api
			// console.log("User Click Rating", this.answer)
			this.$store.commit("setGaveFeedback", true);
			this.modalFeedback = false;
			// dont want to trigger error if token expires
			// await this.$store.dispatch("getAuth");

			try {
				var myHeaders = new Headers();
				myHeaders.append("Authorization", `Bearer ${this.$store.state.token}`);
				myHeaders.append("username", this.$store.state.username);
				myHeaders.append("rating", this.answer);
				var requestOptions = {
					method: "POST",
					headers: myHeaders,
				};

				const res = await fetch(`${APIURL}/set-ratings`, requestOptions);
			} catch {}
		},
	},
	watch: {
		router() {
			if (this.$route.name == "PdfView") {
				this.modalFeedback = true;
			} else {
				// let entering the pdf viewer trigger it. and it wont close until they interact with it
				// this.modalFeedback = false
			}
		},
	},
};
</script>
