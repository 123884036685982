<template>

	<div id="search bar" class="flex justify-center flex-col">

		<form>
			<div class="flex flex-row mx-auto text-firstBlue border-2 border-gray-300 bg-white h-10 pr-2 min-w-full rounded-full">
				<input
					class=" text-sm font-roboto w-11/12 ml-5 focus:outline-none"
					v-model="searchQuery" type="search" name="search" placeholder="Search" autocomplete="off"
					@focus="this.$store.commit('setModalSearch',true)" @keypress.prevent=""/>
				<button type="submit" class="ml-auto mr-0">
					<svg id="Capa_1" class="text-firstBlue h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
						viewBox="0 0 56.966 56.966" style="enable-background: new 0 0 56.966 56.966"
						xml:space="preserve" width="512px" height="512px">
						<path
							d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
					</svg>
				</button>
			</div>
		</form>
	</div>



</template>

<script>
export default {
	name: "BaseSearchBar",
	props: {
		prevQuery: String,
	},
	components: {},
	data() {
		return {
			searchQuery: "",
		};
	},
	computed: {

		viewRoute() {
			return this.$route.name;
		},
		viewQueryRoute() {
			if (this.$route.name == "SearchView") {
				return this.$route.query;
			}
		},
	},
	watch: {
		viewRoute() {
			if (this.viewRoute == "home") {
				this.searchQuery = "";
			}
		},
		viewQueryRoute() {
			this.searchQuery = this.$route.query.query; //if someone direct enter
		},
	},

};
</script>
