<template>
	<div class="home">
		<!-- <h5>{{this.$route.params.pdfId}}</h5>
    <h5>{{this.$route.params.pageParam}}</h5> -->
		<ThePdfViewer
			:id="this.$route.params.pdfId"
			:pageParam="this.$route.params.pageParam"
			
		/>
	</div>
</template>

<script>
import ThePdfViewer from "../components/ThePdfViewer.vue";

export default {
	name: "PdfView",
	props: {},
	components: {
		ThePdfViewer,
	},
};
</script>
