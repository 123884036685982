<template>
	<div class="card  rounded-lg p-5 bg-white my-5 ">
		<div id="results card" :class="[noResult ? '' : '']">
			<div id="result found" v-show="!noResult">
				<div id="title-date" class="flex flex-row justify-between items-start">
					<span
						class="flex flex-row font-roboto font-semibold text-left text-md text-firstGreen w-3/5 items-start justify-items-start md:text-lg hover:text-secondGreen"
					>
						<router-link :to="getLink" @click="setTracker(result.text, result.scores)">{{
							result.title
						}}</router-link>
					</span>

					<div id="date" class="flex flex-col pl-5 md:flex-row">
						<span class="text-slate-600 font-light text-sm">Report Date: </span>
						<span class="text-right text-slate-600 font-medium pr-4 text-sm">{{
							result.date
						}}</span>
					</div>
					<!-- <p class="text-slate-600">Page: {{ result.page }}</p> -->
				</div>
				<div id="meta-data" class="grid grid-cols-3 md:grid-cols-12 md:pt-4">
					<div class="md:pr-5 md:py-1 md:col-span-3">
						<p class="text-slate-400 font-light text-xs md:text-sm">Project</p>
						<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ result.project }}</p>
					</div>
					<div class="md:pr-5 md:py-1 md:col-span-3">
						<p class="text-slate-400 font-light text-xs md:text-sm">Sector</p>
						<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ result.sector }}</p>
					</div>
					<div class="md:pr-5 md:py-1 md:col-span-3">
						<p class="text-slate-400 font-light text-xs md:text-sm">Portfolio</p>
						<p class="text-slate-600 font-medium text-xs md:text-sm">{{ result.port }}</p>
					</div>
					<div class="md:pr-5 md:py-1 md:col-span-3">
						<p class="text-slate-400 font-light text-xs md:text-sm">Consultant</p>
						<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ result.consultant }}</p>
					</div>
					<div class="md:pr-5 md:py-1 md:col-span-3 ">
						<p class="text-slate-400 font-light text-xs md:text-sm">Asset Class</p>
						<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ result.asset_class }}</p>
					</div>
					<div class="md:pr-5 md:py-1 md:col-span-3">
						<p class="text-slate-400 font-light text-xs md:text-sm">Geography</p>
						<p class="text-slate-600 font-medium pr-8 text-xs md:text-sm">{{ result.geography }}</p>
					</div>
				</div>

				<p class="underline font-light text-firstGreen hover:text-secondGreen pt-3">
					<a :href="result.url" target="_blank" rel="noopener noreferrer"
						>Download PPDF Here</a
					>
				</p>
				<div class="dropdown pt-3 py-1 col-span-6 z-50">
					<a
						class="dropdown-toggle px-2 py-2 text-firstBlue bg-gray-100 font-light text-sm hover:bg-thirdBlue  leading-tight rounded  focus:outline-none focus:ring-0 transition duration-150 ease-in-out flex items-center"
						href="#"
						type="button"
						id="dropdownMenuButton2"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						Text Found in the Document:
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fas"
							data-icon="caret-down"
							class="w-2 ml-2"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 320 512"
						>
							<path
								fill="currentColor"
								d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
							></path>
						</svg>
					</a>
					<div
						class="dropdown-menu  hidden bg-white text-base drop-shadow py-2 rounded-lg  mt-1  m-0 px-3 text-left "
						aria-labelledby="dropdownMenuButton2"
					>
						<p class="text-sm text-slate-600" v-for="i in result.text.length">
							<!-- {{ result.page[i - 1] }}: {{ result.text[i - 1] }} -->

							<strong>• Page {{ result.page[i - 1] }}: </strong>
							<router-link
								class="italic hover:text-secondGreen"
								:to="getPageLink(result.id, result.page[i - 1])"
								@click="setTracker(result.text[i - 1], result.scores)"
							>
								{{ result.text[i - 1] }}</router-link
							>
						</p>
					</div>
				</div>
			</div>
			<div id="no result found" v-show="noResult">
				<span>Your search -{{ $store.getters.getSearchQuery }}-</span>

				<span>
					did not match any documents.<br /><br />
					Suggestions: <br /><br />
					• Remove some filters.<br />
					• Try different keywords. <br />
					• Try more general keywords. <br />
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchResultDisplay",
	props: {
		result: Object,
	},
	computed: {
		getLink() {
			return `/pdf-view/id/${this.result.id}/page/${this.result.page[0]}`;
		},
		noResult() {
			if (this.result.page == "") {
				return true;
			} else return false;
		},
	},
	methods: {
		setTracker(text, scores) {
			// console.log("setTextFoundClick",text)
			this.$store.commit("setTextFoundClick", text);
			// console.log("setSimilarityScoreClick",scores)
			this.$store.commit("setSimilarityScoreClick", scores);
		},
		getPageLink(id, page) {
			return `/pdf-view/id/${id}/page/${page}`;
		},
	},
};
</script>

<style></style>
