<template>
	<div class="flex flex-row my-3">
		<h3 class="font-semibold text-left text-firstBlue w-1/3">Select Filter</h3>
		<button
			class="font-oswald bg-firstBlue rounded text-sm text-white mx-1 px-1 py-1 w-1/3"
			@click="clearBtn"
		>
			Clear
		</button>
		<button
			class="font-oswald bg-firstBlue rounded text-sm text-white mx-1 px-1 py-1 w-1/3"
			@click="filterBtn"
		>
			Apply Filter
		</button>
	</div>
	<div>
		<input id="exact-match" type="checkbox" v-model="exactMatchCheckBox" :value="true" />
		<label for="exact-match" class="text-slate-600"> Exact Match</label>
	</div>

	<div>
		<SearchFilterCategory categoryName="Title" filterId="collapseFilterTitle" />
		<SearchFilterCategory categoryName="Consultant" filterId="collapseFilterConsultant" />
		<SearchFilterCategory categoryName="Project" filterId="collapseFilterProject" />
		<SearchFilterCategory categoryName="Asset Class" filterId="collapseFilterAssetClass" />
		<SearchFilterCategory categoryName="Sector" filterId="collapseFilterSector" />
		<SearchFilterCategory categoryName="Geography" filterId="collapseFilterGeography" />
		<SearchFilterCategory categoryName="Year" filterId="collapseFilterYear" />
	</div>
</template>

<script>
import SearchFilterCategory from "./SearchFilterCategory.vue";
export default {
	name: "SearchFilter",
	components: {
		SearchFilterCategory,
	},
	methods: {
		async filterBtn() {
			try {
				let endpoint = this.$route.query.query;
				this.$router.push({
					name: "SearchView",
					query: {
						query: endpoint,
						title: this.$store.state.titleSearchFilter,
						consultant: this.$store.state.consultantSearchFilter,
						project: this.$store.state.projectSearchFilter,
						year: this.$store.state.yearSearchFilter,
						asset_class:this.$store.state.assetClassSearchFilter,
						geography:this.$store.state.geoSearchFilter,
						sector:this.$store.state.sectorSearchFilter,
						exact: this.$store.state.exactMatchCheckBox,
					}, 
				});
			} catch (err) {
				console.warn(err);
			}
		},
		clearBtn() {
			try {
				// let bool = (this.$store.state.titleSearchFilter.length > 0) || (this.$store.state.consultantSearchFilter.length) || (this.$store.state.projectSearchFilter.length > 0) || (this.$store.state.yearSearchFilter.length > 0)
				this.$store.commit("clearSearchFilter");
				this.$store.commit("clearSearchAllBox");
				this.$store.commit("setExactMatchCheckBox", false);
				let endpoint = this.$route.query.query;

				// console.log("🚀 ~ file: SearchFilter.vue ~ line 49 ~ clearBtn ~ bool", bool)

				if (true) {
					this.$router.push({
						name: "SearchView",
						query: {
							query: endpoint,
							title: this.$store.state.titleSearchFilter,
							consultant: this.$store.state.consultantSearchFilter,
							project: this.$store.state.projectSearchFilter,
							year: this.$store.state.yearSearchFilter,
							asset_class:this.$store.state.assetClassSearchFilter,
							geography:this.$store.state.geoSearchFilter,
							sector:this.$store.state.sectorSearchFilter,
						},
					});
				}
			} catch (err) {
				console.warr(err);
			}
		},
	},
	computed: {
		exactMatchCheckBox: {
			get() {
				return this.$store.state.exactMatchCheckBox;
			},
			set(value) {
				this.$store.commit("setExactMatchCheckBox", value);
			},
		},
	},
	data() {
		return {
		};
	},
};
</script>
